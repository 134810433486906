/**
 * Styles for the ABTG theme.
 */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79D0KEwA.ttf) format('truetype');
}
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/ptsans/v17/jizfRExUiTo99u79B_mh0O6tKA.ttf) format('truetype');
}
html {
  height: 100%;
}
body {
  font-family: 'PT Sans', 'Segoe UI';
  position: relative;
  width: 100%;
  min-height: 100%;
  margin: 0 !important;
  padding-bottom: 100px;
}
html body.admin-menu,
body.admin-menu {
  margin-top: 0px!important;
}
body.toolbar-drawer {
  padding-top: 0px;
}
body:not(.front).navbar-is-fixed-top {
  padding-top: 145px!important;
}
body.logged-in:not(.front).navbar-is-fixed-top {
  padding-top: 145px!important;
}
h1,
h1.page-header {
  size: 30px;
  color: #000;
  font-weight: bold;
}
h2 {
  font-weight: bold;
  color: #188CC8;
  font-size: 2em;
}
h3 {
  font-weight: bold;
  size: 20px;
  color: #000;
}
p {
  font-size: 1.15em;
  line-height: 1.5em;
}
.front p {
  line-height: 1.75em;
}
.sidebar-second .panel .panel-body {
  font-size: 15px;
  line-height: 22px;
}
.sidebar-second .panel .panel-title {
  font-size: 20px;
}
.sidebar-CI {
  border: #E84B3A solid 3px;
  background-color: #fff;
  margin: 10px;
}
.sidebar-CI img {
  max-width: 100%;
  height: auto;
  display: block;
}
.sidebar-CI div {
  margin: 6px;
  background-color: #F2F1F1;
  padding: 12px;
  text-transform: uppercase;
}
.sidebar-CI div a {
  font-weight: 500;
}
.sidebar-CI div .fa-arrow-right {
  color: #E84B3A;
  float: right;
}
.sidebar-CI .ci {
  background-color: #E84B3A;
  margin: 0;
  padding: 13px 17px;
}
.sidebar-CI .ci a {
  color: #fff;
  font-weight: 700;
  font-size: 1.2em;
}
.sidebar-CI .ci .fa-arrow-right {
  color: #fff;
  float: right;
}
.subscribe-enews {
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;
}
.subscribe-enews input:-webkit-autofill {
  background-color: #fff;
}
#mc_embed_signup {
  background: #fff;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  border-top: #7f9fac solid 2px;
  padding-top: 10px;
}
#mc_embed_signup h3 {
  font-size: 1.8em;
  margin-left: auto;
  margin-right: auto;
}
#mc_embed_signup #mc_embed_signup_scroll {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}
#mc_embed_signup #mc_embed_signup_scroll input[type="submit"] {
  border: none;
}
#mc_embed_signup #mc_embed_signup_scroll input {
  padding-left: 5px;
}
#mc_embed_signup #mc_embed_signup_scroll .btn-ci {
  padding: 3px 12px 3px 12px;
  margin-left: 10px;
  background-color: #7f9fac;
  color: #fff;
}
#mc_embed_signup #mc_embed_signup_scroll label {
  font-size: 1.2em;
}
.tech-resources {
  display: flex;
  flex-wrap: wrap;
}
.tech-resources > div {
  width: 155px;
  text-align: center;
  color: #fff;
  margin: 10px auto 20px;
  font-weight: 500;
  font-size: larger;
  display: flex;
  flex-direction: column;
}
.tech-resources > div h4 {
  min-height: 80px;
  margin: 0;
  padding: 8px 0;
  background-color: #188CC8;
}
.tech-resources > div .tech-icon {
  background-color: #fff;
  color: #000;
  padding: 10px;
  border: #188CC8 solid 2px;
}
.tech-resources > div .tech-icon img {
  width: 100%;
}
header.navbar-default {
  background-color: #fff;
  padding: 1em 0;
  border-top: #188CC8 solid 5px;
  border-bottom: #7f9fac solid 2px;
}
header.navbar-default .navbar-toggle {
  margin-right: 30px;
}
header.navbar-default .navbar-toggle > .icon-bar {
  color: #7f9fac;
}
header.navbar-default .navbar-brand {
  padding: 5px 0;
}
header.navbar-default .navbar-brand .siteLogo {
  top: 2px;
  left: 50px;
  position: absolute;
  width: 85px;
  margin-bottom: 5px;
}
header.navbar-default .navbar-brand .siteName {
  display: inline-block;
  margin-top: 16px;
  margin-left: 210px;
  max-width: 180px;
  color: #000;
  font-size: 85%;
  line-height: 1.2;
  font-family: microgramma-extended, sans-serif;
  font-style: normal;
  font-weight: 500;
}
header.navbar-default .navbar-nav {
  text-transform: uppercase;
  font-size: 1.1em;
}
header.navbar-default .navbar-nav > li.active > a,
header.navbar-default .navbar-nav > li > a {
  padding: 5px 22px;
  color: #188CC8;
}
header.navbar-default .navbar-nav > li.active > a:hover,
header.navbar-default .navbar-nav > li > a:hover,
header.navbar-default .navbar-nav > li.active > a:focus,
header.navbar-default .navbar-nav > li > a:focus {
  color: #7f9fac;
}
header.navbar-default .navbar-nav li.dropdown.open > a.dropdown-toggle {
  background-color: transparent;
  color: #188CC8;
}
header.navbar-default .navbar-nav > li.active > a {
  background-color: transparent;
}
header.navbar-default .navbar-nav > li.active > a:hover,
header.navbar-default .navbar-nav > li.active > a:focus {
  background-color: transparent;
}
header.navbar-default .navbar-collapse {
  border: none;
}
header.navbar-default .navbar-collapse.collapse > nav {
  float: right;
}
header.navbar-default .navbar-collapse.collapse > nav .region.region-navigation {
  float: right;
}
header.navbar-default .navbar-collapse.collapse > nav .region.region-navigation section {
  padding: 1em;
}
header.navbar-default .navbar-collapse.collapse > nav .region.region-navigation section a,
header.navbar-default .navbar-collapse.collapse > nav .region.region-navigation section span {
  position: relative;
  top: 1px;
}
header.navbar-default .navbar-collapse.collapsing,
header.navbar-default .navbar-collapse.collapse.in {
  width: 100%;
}
header.navbar-default .navbar-collapse.collapsing > nav,
header.navbar-default .navbar-collapse.collapse.in > nav {
  float: left;
}
header.navbar-default .navbar-collapse.collapsing > nav .region.region-navigation,
header.navbar-default .navbar-collapse.collapse.in > nav .region.region-navigation {
  float: left;
}
header.navbar-default .navbar-collapse.collapsing > nav .region.region-navigation section,
header.navbar-default .navbar-collapse.collapse.in > nav .region.region-navigation section {
  padding-left: 0px;
}
header.navbar-default .navbar-collapse.collapsing .dropdown-menu li a,
header.navbar-default .navbar-collapse.collapse.in .dropdown-menu li a {
  color: #555;
  padding-left: 50px;
}
header.navbar-default .navbar-collapse.collapsing .dropdown-menu li.active a,
header.navbar-default .navbar-collapse.collapse.in .dropdown-menu li.active a {
  color: #fff;
  background-color: #188CC8;
}
.page-header {
  font-size: 32px;
}
.panel-primary {
  border: 0;
  box-shadow: none;
}
.panel-primary > .panel-heading {
  background-color: #7f9fac;
  border: 0;
}
.panel-primary > .panel-heading .panel-title {
  font-weight: bold;
}
.panel-primary > .panel-body {
  padding: 1em 0;
}
.hero > .hero-bg {
  background-color: #188CC8;
  position: absolute;
  height: 250px;
  width: 100%;
  left: 0px;
  z-index: -1000;
  top: 0px;
}
.hero > .hero-logo {
  display: block;
  width: 350px;
  padding-top: 20px;
  margin: 0 auto;
}
.hero .region-slogan {
  width: 50%;
  padding-top: 1em;
  margin: 0 auto;
  text-align: center;
}
@media (min-width: 768px) {
  .hero > .hero-bg {
    height: 320px;
  }
  .hero > .hero-logo {
    padding-top: 70px;
  }
}
@media (min-width: 992px) {
  .hero > .hero-bg {
    height: 320px;
  }
  .hero > .hero-logo {
    padding-top: 100px;
  }
}
@media (min-width: 1100px) {
  .hero > .hero-bg {
    height: 250px;
  }
  .hero > .hero-logo {
    padding-top: 20px;
  }
}
#block-aggregator-feed-1 .panel-heading {
  background-color: transparent;
  background-image: url('/sites/default/files/EnergyEfficiencyNews.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 50%;
}
#block-aggregator-feed-1 .panel-heading > .panel-title {
  color: transparent;
  pointer-events: none;
}
#block-aggregator-feed-1 .more-link {
  font-weight: bold;
  text-align: right;
}
#block-aggregator-feed-1 .more-link:after {
  content: '\f054';
  font-size: 0.85em;
  padding-left: 0.5em;
  font-family: 'FontAwesome';
  color: #188CC8;
}
.page-header {
  border-bottom: none;
}
.footer {
  width: 100%;
  padding-top: 0;
  border: none;
  padding-bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.footer-bkg {
  background-color: #188CC8;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 600;
  color: #2e2e2e;
}
.footer-bkg a {
  color: #fff;
  font-weight: 500;
}
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
#block-block-4 {
  border-bottom: 2px solid #ddd;
}
#block-block-4 h2 {
  font-weight: 700;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
  background-color: #7f9fac;
  color: white;
  padding: 10px 15px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
#block-menu-block-1 {
  border-bottom: 2px solid #ddd;
}
#block-menu-block-1 ul li {
  padding: 10px 0;
}
#block-menu-block-1 ul li:before {
  content: '\f054';
  font-family: 'FontAwesome';
  color: #188CC8;
  padding-left: 5px;
}
#block-menu-block-1 ul li a {
  display: inline;
  margin-left: -15px;
  padding-left: 25px;
  padding-right: 5px;
  position: relative;
}
#block-menu-block-1 ul li a:hover {
  background-color: transparent;
}
.field.field-name-body {
  padding-bottom: 1.5em;
}
.views-field-view-node {
  padding-top: 1.25em;
}
.views-field-title strong {
  font-size: 1.25em;
}
.btn-links.field-items {
  position: relative;
  top: -0.75em;
}
header.navbar-default .navbar-collapse.collapsing .region.region-navigation,
header.navbar-default .navbar-collapse.in .region.region-navigation {
  float: left;
}
.toboggan-unified.login #login-links {
  margin-bottom: 1em;
}
.toboggan-unified.login #login-links a {
  font-size: 0.75em;
  padding: 0.75em;
  border-radius: 4px;
  border: none;
}
.toboggan-unified.login #login-links a:first-child {
  background-color: #188CC8;
  color: #fff;
}
.toboggan-unified.login #login-links a:last-child {
  background-color: #7f9fac;
  color: #fff;
}
/**
body.page-node-87 {
  h1.page-header {
      background: url('/sites/appliedbuildingtech.com/files/uploads/header-images/acc_fsc_heade-noline.png') no-repeat;
      background-size:inherit;
      padding-top: 150px;
      line-height: 118%;
  }
  @media (min-width: @screen-sm-min) {
    h1.page-header {
      background: url('/sites/appliedbuildingtech.com/files/uploads/header-images/acc_fsc_header.png') no-repeat;
      background-size:contain;
      padding-left: 275px;
      height: 131px;
      line-height: 118%;
      max-width: 655px;
      padding-left: 285px;
      padding-top: 24px;
    }
  }
}
*/
@media (max-width: 992px) {
  .sidebar-CI {
    width: 160px;
    margin-right: 10px;
  }
}
@media (max-width: 1100px) {
  .siteLogo {
    width: 75px !important;
    height: auto;
    margin-top: 3px;
    left: 20px !important;
  }
  .siteName {
    margin-left: 135px !important;
  }
  .main-container {
    margin-top: 45px;
  }
  header.navbar-default .navbar-nav > li.active > a,
  header.navbar-default .navbar-nav > li > a {
    padding: 5px 15px;
  }
  .navbar-collapse {
    clear: both;
  }
  .navbar-collapse nav {
    float: none !important;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .sidebar-CI {
    width: 80%;
    margin: auto;
  }
  .siteLogo {
    height: auto;
    left: 10px !important;
  }
  .siteName {
    margin-left: 115px !important;
  }
  .main-container {
    margin-top: 5px;
  }
  .navbar-collapse nav {
    display: inline-block;
  }
}
@media (max-width: 340px) {
  .siteName {
    visibility: hidden;
    max-width: 0 !important;
    display: none;
  }
}
