/**
 * Styles for the ABTG theme.
 */

//@palette-primary: #0A76B4;
@palette-primary: #188CC8;
@palette-secondary: #7f9fac;
@palette-text-light: #7f9fac;
@palette-text-light-hover: #7f9fac;
@palette-ci: #E84B3A;

@screen-sm-min: 768px;
@screen-md-min: 992px;
@screen-lg-min: 1200px;

@font-stack-sans: 'PT Sans', 'Segoe UI';

@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,700);

html {
  height: 100%;
}

body {
  font-family: @font-stack-sans;
  position: relative;
  width: 100%;
  min-height: 100%;
  margin: 0 !important;
  padding-bottom: 100px;
}

html body.admin-menu, body.admin-menu {
  margin-top:0px!important;
}

body.toolbar-drawer {
  padding-top:0px;
}

body:not(.front).navbar-is-fixed-top {
  padding-top:145px!important;
}

body.logged-in:not(.front).navbar-is-fixed-top {
  padding-top:145px!important;
}

h1, h1.page-header {
  size:30px;
  color:#000;
  font-weight:bold;
}

h2 {
  font-weight:bold;
  color: @palette-primary;
  font-size:2em;
}

h3 {
  font-weight:bold;
  size: 20px;
  color:#000;
}

p {
  font-size:1.15em;
  line-height:1.5em;
}

.front p {
  line-height:1.75em;
}

.sidebar-second .panel {
  & .panel-body {
    font-size:15px;
    line-height:22px;
  }
  & .panel-title {
    font-size:20px;
  }
}

.sidebar-CI {
  border: @palette-ci solid 3px;
  background-color: #fff;
  margin: 10px;
  & img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  & div {
    margin: 6px;
    background-color: #F2F1F1;
    padding: 12px;
    text-transform: uppercase;
    & a {
      font-weight: 500;
    }
    & .fa-arrow-right {
      color:@palette-ci;
      float: right;
    }
  }
}

.sidebar-CI .ci {
  background-color: @palette-ci;
  margin: 0;
  padding: 13px 17px;
  & a {
    color: #fff;
    font-weight: 700;
    font-size: 1.2em;
  }
  & .fa-arrow-right {
    color: #fff;
    float: right;
  }
}

.subscribe-enews {
  //width: 100vw;
  position: relative;
  //margin-left: -50vw;
  //left: 50%;
  //right: 50%;
  margin-top: 20px;
  margin-bottom: 30px;

  & input:-webkit-autofill {
      background-color: #fff;
  }
}

#mc_embed_signup {
  background:#fff; 
  //clear:left; 
  font-size:14px; 
  width:100%;
  display: flex;
  justify-content: center;
  text-align: center;
  border-top: @palette-secondary solid 2px;
  padding-top: 10px;

  & h3 {
      font-size: 1.8em;
      margin-left: auto;
      margin-right: auto;
  }

  & #mc_embed_signup_scroll {
      display: flex;
      justify-content: center;
      padding-top: 5px;

      & input[type="submit"] {
        border: none;
      }

      & input {
          padding-left: 5px;
      }

      & .btn-ci {
          padding: 3px 12px 3px 12px;
          margin-left: 10px;
          background-color: @palette-secondary;
          color: #fff;
      }

      & label {
          font-size: 1.2em;
      }
  }
}

.tech-resources {
  display: flex;
  flex-wrap: wrap;
  & > div {
    width: 155px;
    text-align: center;
    color: #fff;
    margin: 10px auto 20px;
    font-weight: 500;
    font-size: larger;
    display: flex;
    flex-direction: column;

    h4 {
      min-height: 80px;
      margin: 0;
      padding: 8px 0;
      background-color: @palette-primary;
    }

    .tech-icon {
      background-color: #fff;
      color: #000;
      padding: 10px;
      border: @palette-primary solid 2px;
      //justify-self: flex-end;
      & img {
        width: 100%;
      }
    }
  }
}

header.navbar-default {
  background-color: #fff;
  padding: 1em 0;
  border-top: @palette-primary solid 5px;
  border-bottom: @palette-secondary solid 2px;

  .navbar-toggle {
    margin-right: 30px;
  }
  .navbar-toggle > .icon-bar {
    color:@palette-text-light;
  }

  .navbar-brand {
    padding: 5px 0;

    .siteLogo {
      top:2px;
      left:50px;
      position: absolute;
      width: 85px;
      margin-bottom: 5px;
    }

    .siteName {
      display: inline-block;
      margin-top: 16px;
      margin-left: 210px;
      max-width: 180px;
      color: #000;
      font-size: 85%;
      line-height: 1.2;
      font-family: microgramma-extended, sans-serif;
      font-style: normal;
      font-weight: 500;
    }
  }

  & .navbar-nav {
    text-transform: uppercase;
    font-size: 1.1em;

    & > li.active > a, & > li > a {
      padding: 5px 22px;
      color: @palette-primary;
      &:hover, &:focus {
        color: @palette-text-light-hover;
      }
    }
    & li.dropdown.open > a.dropdown-toggle {
      background-color:transparent;
      color: @palette-primary;
    }
    & > li.active > a {
      background-color: transparent;
      &:hover, &:focus {
        background-color: transparent;
      }
    }
  }
  & .navbar-collapse {
    border:none;
    &.collapse > nav {
      float:right;
      & .region.region-navigation {
        float:right;
        & section {
          padding:1em;
          & a, span {
            position:relative;
            top:1px;
          }
        }
      }
    }

    &.collapsing, &.collapse.in {
      width:100%;
      & > nav {
       float:left;
        & .region.region-navigation {
          float:left;
          & section {
            padding-left:0px;
          }
        }
      }
      & .dropdown-menu li a {
        color:#555;
        padding-left: 50px;
      }
      & .dropdown-menu li.active a {
        color:#fff;
        background-color: @palette-primary;
      }
    }
  }

}

.page-header {
  font-size:32px;
}

.panel-primary {
  border:0;
  box-shadow:none;
  & > .panel-heading {
    background-color: @palette-secondary;
    border:0;
    & .panel-title {
      font-weight:bold;
    }
  }
  & > .panel-body {
    padding: 1em 0;
  }
}

.hero {
  & > .hero-bg {
    background-color:@palette-primary;
    position:absolute;
    height:250px;
    width:100%;
    left:0px;
    z-index:-1000;
    top:0px;
  }

  & > .hero-logo {
    display:block;
    width:350px;
    padding-top:20px;
    margin:0 auto;
  }
  & .region-slogan {
    width:50%;
    padding-top:1em;
    margin:0 auto;
    text-align:center;
  }
  @media(min-width: @screen-sm-min) {
    & > .hero-bg {
      height:320px;
    }
    & > .hero-logo {
      padding-top:70px;
    }
  }

  @media(min-width: @screen-md-min) {
    & > .hero-bg {
      height:320px;
    }
    & > .hero-logo {
      padding-top:100px;
    }
  }
  @media(min-width: 1100px) {
    & > .hero-bg {
      height:250px;
    }
    & > .hero-logo {
      padding-top:20px;
    }
  }
}

#block-aggregator-feed-1 {
   & .panel-heading {
    background-color:transparent;
    background-image: url('/sites/default/files/EnergyEfficiencyNews.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 50%;
    & > .panel-title {
      color:transparent;
      pointer-events:none;
    }
  }
  & .more-link {
    font-weight:bold;
    text-align:right;
    &:after {
      content: '\f054';
      font-size:0.85em;
      padding-left:0.5em;
      font-family: 'FontAwesome';
      color: @palette-primary;
    }
  }
}

.page-header {
  border-bottom:none;
}

.footer {
  width: 100%;
  padding-top: 0;
  border: none;
  padding-bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.footer-bkg {
  background-color: @palette-primary;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 600;
  color: rgb(46, 46, 46);

  & a {
    color: #fff;
    font-weight:500;
  }
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

#block-block-4 {
  border-bottom: 2px solid #ddd;
  h2 {
    font-weight: 700;
    font-size: 20px;
    margin-top: 0;
    margin-bottom:0;
    background-color:#7f9fac;
    color: white;
    padding: 10px 15px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

  }
}

#block-menu-block-1 {
  border-bottom: 2px solid #ddd;
  & ul li {
   padding:10px 0;
  }
  & ul li:before {
    content: '\f054';
    font-family: 'FontAwesome';
    color: @palette-primary;
    padding-left:5px;
  }
  & ul li a {
    display:inline;
    margin-left:-15px;
    padding-left:25px;
    padding-right:5px;
    //width:100%;
    position:relative;
    &:hover {
      background-color:transparent;
    }
  }
}

.field.field-name-body {
  padding-bottom:1.5em;
}

.views-field-view-node {
  padding-top:1.25em;
}

.views-field-title strong {
  font-size:1.25em;
}

.btn-links.field-items {
  position:relative;
  top:-0.75em;
}

header.navbar-default .navbar-collapse.collapsing .region.region-navigation,
header.navbar-default .navbar-collapse.in .region.region-navigation {
  float:left;
}

.toboggan-unified.login #login-links {
  margin-bottom:1em;
  & a {
    font-size:0.75em;
    padding:0.75em;
    border-radius:4px;
    border:none;
    &:first-child {
      background-color:@palette-primary;
      color:#fff;
    }
    &:last-child {
      background-color:@palette-secondary;
      color:#fff;
    }
  }
}

/**
body.page-node-87 {
  h1.page-header {
      background: url('/sites/appliedbuildingtech.com/files/uploads/header-images/acc_fsc_heade-noline.png') no-repeat;
      background-size:inherit;
      padding-top: 150px;
      line-height: 118%;
  }
  @media (min-width: @screen-sm-min) {
    h1.page-header {
      background: url('/sites/appliedbuildingtech.com/files/uploads/header-images/acc_fsc_header.png') no-repeat;
      background-size:contain;
      padding-left: 275px;
      height: 131px;
      line-height: 118%;
      max-width: 655px;
      padding-left: 285px;
      padding-top: 24px;
    }
  }
}
*/

  @media(max-width: @screen-md-min) {
    .sidebar-CI {
      width: 160px;
      margin-right: 10px;
    }
  }

  @media(max-width: 1100px) {    
    .siteLogo {
      width: 75px !important;
      height: auto;
      margin-top: 3px;
      left: 20px !important;
    }

    .siteName {
      margin-left: 135px !important;
    }

    .main-container {
      margin-top: 45px;
    }

    header.navbar-default .navbar-nav>li.active>a, header.navbar-default .navbar-nav>li>a {
        padding: 5px 15px;
    }

    .navbar-collapse {
      clear: both;

      nav {
        float: none !important;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        margin-top: 10px;  
      }
    }
  }

  @media(max-width: 767px) {
    .sidebar-CI {
      width: 80%;
      margin: auto;
    }
    .siteLogo {
      //width: 80px !important;
      height: auto;
      left: 10px !important;
    }
    .siteName {
      margin-left: 115px !important;
    }

    .main-container {
      margin-top: 5px; 
    }
    .navbar-collapse nav {
      display: inline-block;
      //justify-content: space-evenly;
    }
  }

  @media(max-width: 340px) {
    .siteName {
      visibility: hidden;
      max-width: 0 !important;
      display: none;
    }
  }
